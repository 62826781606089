<template>
  <div class="echart">
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="top-left">
          <div class="top-left-top">
            <span>{{account.accountName}}</span>
          </div>
          <div class="top-left-middle">
            <div class="balance">
              <div class="seconds-title" >账户余额(元):<span class="amount">{{account.amount/100}}</span></div>
              <!-- <el-statistic
                group-separator=","
                :precision="2"
                :value="32131231"
              ></el-statistic> -->
            </div>
          </div>
          <div class="top-left-bottom">
            <!-- <el-button @click="noDetails()" class="btn" type="danger" size="small">发薪</el-button> -->
            <el-button class="btn1" plain size="small" @click="infoDialogVisibles()">提现</el-button>
            <el-button @click="noDetailss()" class="btn1" plain size="small">交易明细</el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="top-right">
          <div class="top-right-middle">
            <div class=" piechart-right">
             <div class="data_name">
                <span class="second-titles">本月发薪次数：</span>
                <div class="data_amount">{{PayrollNumber.number}}</div>
              </div>
              <div class="data_name">
                <span class="second-titles">本月成功发薪(元)：</span>
                <div class="data_amount">{{PayrollInfo.total_Salary}}</div>
              </div>
              <div class="data_name">
                <span class="second-titles">本月成功发薪人数：</span>
                <div class="data_amount">{{PayrollInfo.total_Salary_Number}}</div>
              </div>
              <!-- <div class="data_name">
                  <span class="second-titles">已完成开票申请：</span>
                  <div class="data_amount" style="font-size: 18px;">敬请期待</div>
              </div>
              <div class="data_name">
                  <span class="second-titles">可开票额度(元)：</span>
                  <div class="data_amount" style="font-size: 18px;">敬请期待</div>
              </div> -->
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 16px;">
      <el-col :span="8">
        <div class="main-middle" @click="noDetailcontract()">
          <img src="@/static/system/images/charticon.png" alt="" />
          <div style="text-align: left;margin-left: 16px;">
            <div class="middle-title">
              一键发薪
            </div>
            <div class="second-title">
              上传实发账单操作发薪
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="main-middle" @click="noDetailsalary()">
          <img src="@/static/system/images/charticon.png" alt="" />
          <div style="text-align: left;margin-left: 16px;">
            <div class="middle-title">
              发薪记录
            </div>
            <div class="second-title">
              查看发薪结果和明细
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="main-middle" @click="noDetailinvoice()">
          <img src="@/static/system/images/charticon.png" alt="" />
          <div style="text-align: left;margin-left: 16px;">
            <div class="middle-title">
              发票管理
            </div>
            <div class="second-title">
              点击提交开票申请
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="main-bottom" style="margin-top: 16px;">
      <div class="main-bottom-top">
        <span>发薪金额(元)</span>
        <div>
          <div class="icon-text-red">发薪</div>
        </div>
      </div>
      <div id="barchart"></div>
    </div>
    <div>
      <el-dialog title="提现转账" :visible.sync="infoDialogVisible" width="30%" >
            <el-form  :model="infoForm" ref="infoForm" :rules="rules" :label-position="'left'" label-width="75px">
              <el-form-item label="提现账户:">
                <el-input v-model="infoForm.crtacc" disabled></el-input>
              </el-form-item>
              <el-form-item label="提现银行:">
                <el-input v-model="infoForm.crtbnk" disabled></el-input>
              </el-form-item>
              <el-form-item label="提现金额:">
                <el-input v-model="infoForm.money"></el-input>
              </el-form-item>    
              <el-form-item label="提现备注:">
                <el-input v-model="infoForm.transferComment"></el-input>
              </el-form-item>  
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="infoDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
  </div>
</template>
<script>
import echarts from 'echarts'
export default {
  data() {
    return {
      activeName: '',
      piechart: null,
      barchart: null,
      PayrollInfo:'',
      PayrollNumber:'',
      account:{},
      tx:{},
      queryForm:{
        id:''
      },
      infoForm:{
        crtacc:'',
        crtbnk:'',
        money:'',
        transferComment:'',
        flag:'',
        tenantId:'',
        accountName:'',
        bankcard:'',
        walletId:'',
        brdnbr:'',
      },
      infoDialogVisible:false,
      rules: {
        money:[
            { required: true, message: '请输入提现金额！', trigger: 'change' }
        ],
        transferComment:[
            { required: true, message: '请输入提现备注！', trigger: 'change' }
        ],
      }
    }
  },
  mounted() {
    this.findPageHistogram();
    this.listPayrollInfos();
    this.listPayrollNumber();
    this.subunitBalance();
  },
  methods: {

    //提现
    // async submit() {
    //   await this.$refs["infoForm"].validate((valid) => {
    //     if (valid) {
    //       this.api.salaryApi.withdrawal(this.infoForm).then(res => {
    //         console.log("-----",res)
    //           this.PayrollInfo = res.data.data;
    //         });
    //     }else{
    //       return false;
    //     }
    //   })
    // },

    async zzs(){
      await this.$refs['infoForm'].validate((valid) => {
        if (valid) {
          this.api.salaryApi.withdrawal(this.infoForm).then(res => {
            console.log("-----",res)
            if(res.data.code == 200){
              this.$message({
                type: 'success',
                message: '提现成功'
              });
              this.infoDialogVisible = false;
            }else{
              this.$message.error(res.data.msg);
              this.infoDialogVisible = false;
            }
            this.subunitBalance();
          });
        } else {
          return false;
        }
      });
    },
    submit() {
        this.$confirm('确认提现吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.zzs();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消提现'
          });          
        });
      },

    //查询提现账户信息
    async txInfo() {
      await this.api.salaryApi.finWithdrawalTenant(this.queryForm).then(res => {
        this.tx = res.data
        console.log("-----",this.tx)
        if(this.tx.tenantIden == 1){
            this.infoForm.crtacc = this.tx.bankcard
            this.infoForm.crtbnk = this.tx.accountName
        }else{
            this.infoForm.crtacc = this.tx.crtacc
            this.infoForm.crtbnk = this.tx.crtbnk
        }
            this.infoForm.tenantId = this.tx.tenantId
        });
    },
    //首页柱状图
    async findPageHistogram() {
      await this.api.salaryApi.findPageHistogram(this.queryForm).then(res => {
          this.pageHistogram = res.data.data;
          this.setBarchart(this.pageHistogram)
        });
    },
    //查询本月发薪人数和金额
    async listPayrollInfos() {
      this.tableLoading = true;
      await this.api.salaryApi.listPayrollInfo(this.queryForm).then(res => {
        console.log("-----",res)
          this.PayrollInfo = res.data.data;
          console.log("-----",this.PayrollInfo)
        });
    },
    //查询账户余额
    async subunitBalance() {
      this.tableLoading = true;
      await this.api.salaryApi.subunitBalance(this.queryForm).then(res => {
          this.account = res.data.data.data;
          console.log("-----sssssssssssss",this.account)
        });
    },

    //查询本月发薪次数
    async listPayrollNumber() {
      this.tableLoading = true;
      await this.api.salaryApi.listPayrollNumber(this.queryForm).then(res => {
        // console.log("-----",res)
          this.PayrollNumber = res.data.data;
          console.log("-----",this.PayrollNumber)
        });
    },

    infoDialogVisibles(){
      this.infoDialogVisible = true;
      this.txInfo();
    },
    

    setPiechart() {
      this.piechart = echarts.init(document.getElementById('piechart'))
      const option = {
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '30',
                fontWeight: 'bold',
              },
              selectedOffset: -10,
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 310, itemStyle: { color: '#fa4b47' } },
              { value: 600, itemStyle: { color: '#ccc' } },
            ],
          },
        ],
      }
      this.piechart.setOption(option)
      this.piechart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0, // 图表中的第一个系列
        dataIndex: 0, // 要高亮的数据项的索引
      })
    },
    setBarchart(arr) {
      console.log('arr:',arr)
      let xAxisDatas = [],totalAmountDatas = []
      for(let i = 0 ;  i < arr.length; i ++){
         xAxisDatas.push(arr[i].startTime)
         totalAmountDatas.push(arr[i].amount)
      }

      this.barchart = echarts.init(document.getElementById('barchart'))
      const option = {
        grid: {
          top: 20,
          left: 60,
          bottom: 30,
          right: 60,
        },
        tooltip: {},
        xAxis: {
          type: 'category',
          data: xAxisDatas,
        },
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
            //发薪金额
          {
            type: 'bar',
            itemStyle: { color: '#fa4b47', barBorderRadius: [10, 10, 0, 0] },
            barMaxWidth: 15,
            data: totalAmountDatas,
          },
          //发票金额
          {
            type: 'bar',
            itemStyle: {
              color: '#faac3c',
              barBorderRadius: [10, 10, 0, 0],
            },
            barMaxWidth: 15,
            // data: [43.3, 43.3, 43.3, 43.3, 43.3, 43.3],
          },
        ],
      }
      this.barchart.setOption(option)
      this.barchart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0, // 图表中的第一个系列
        dataIndex: 0, // 要高亮的数据项的索引
      })
    },
    noDetails(){
        this.$router.push({path: '/salaryList'})
    },
    noDetailss(){
        this.$router.push({path: '/tran'})
    },
    // noDetails(row){
    //     this.$router.push({path: '/payrollLogDetails',query: {salarySummarizingRecordsId: row.id,createTime:row.createTime}})
    // },
    noDetailcontract(){
        this.$router.push({path: '/easyPayList'})
    },
    noDetailinvoice(){
        this.$router.push({path: '/invoiceWritebackList'})
    },
    noDetailsalary(){
        this.$router.push({path: '/payrollLog'})
    },
  },
}
</script>
<style scoped>
>>> .el-statistic .con {
  justify-content: flex-start;
}
.amount{
    margin-left: 30px;
    font-weight: 900;
}
.data_amount{
    margin-top: 20px;
    font-weight: 900;
    font-size: 28px;
}
.btn{
    width: 100px;
}
.btn1{
    width: 100px;
    color: red;
}
.data_name{
    margin-top: 35px;
}
.echart {
  height: calc(100vh - 120px);
  /* background-color: #fff; */
}
.seconds-title{
    font-size: 24px;
}
.top-left,
.top-right {
  background-color: #fff;
  padding: 16px;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.05);
}
.top-left-top,
.top-left-middle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.balance {
  background-color: #fff5f5;
  flex: 1;
  text-align: left;
  margin-top: 16px;
  padding: 16px;
}
.balance + .balance {
  margin-left: 16px;
}
.second-title {
  font-size: 14px;
  color: rgb(149, 149, 149);
}
.second-titles {
  font-size: 14px;
  color: #000;
}
.account {
  font-size: 18px;
  margin-top: 8px;
}
.top-left-bottom {
  margin-top: 16px;
}
.top-right-top {
  display: flex;
  align-items: center;
}

.tabs + .tabs {
  margin-left: 8px;
}
#piechart {
  width: 50%;
  height: 166px;
}
.top-right-middle {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
}
.piechart-right {
  height: 155px;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  margin-top: 0;
}
.main-middle {
  background-color: #fff;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.middle-title {
  font-size: 20px;
  margin-bottom: 8px;
}
.main-bottom {
  background-color: #fff;
  height: 368px;
  padding: 16px;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.05);
}
.main-bottom-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.icon-text-red,
.icon-text-yellow {
  display: inline-block;
}
.icon-text-red::before,
.icon-text-yellow::before {
  content: '';
  display: inline-block;
  background-color: #000;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin: 0 8px;
  vertical-align: middle;
}
.icon-text-red::before {
  background-color: #fa4b47;
}
.icon-text-yellow::before {
  background-color: #faac3c;
}
#barchart {
  height: 350px;
}
</style>
